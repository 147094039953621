@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    cursor: none;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */

    font-family: 'Satoshi';


}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.z-index-0 {
    z-index: 0 !important;
}

.z-index-10 {
    z-index: 10 !important;
}

.z-index-99 {
    z-index: 99 !important;
}

.z-index-999 {
    z-index: 999 !important;
}

.z-index-999999 {
    z-index: 999999 !important;
}


.custom-cursor-scroll {

    border-radius: 50%;
    width: 68px;
    height: 68px;
    border-color: #333333;
    color: #333333;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    pointer-events: none;

    @media (max-width: 768px) {
        display: none;
    }
}


.mask-text {
    border-radius: 50%;
    width: 100;
    height: 100;
    background-color: #333333;
    color: #EDEEE9;
    opacity: 1;
    position: fixed;

}



.minText {
    font-size: 1rem !important;

}

.slider-container {
    display: flex;
    overflow: hidden;

}

.slick-slider {
    width: 1600px;

    @media screen and (max-width: 1868px) {
        width: 100%;
    }


    @media screen and (max-width: 1368px) {
        width: 100%;

    }


}

.slick-slide {
    position: relative;
    display: block !important;
    width: 100%;
}

/* the parent */
.slick-list {
    padding: 0px !important;
    width: 100%;
    overflow: hidden;
}

.slick-slide div {
    width: auto;
    margin-right: 18px;

    @media screen and (max-width: 768px) {
        margin-right: 0;
    }
}

.slick-slide img {
    width: calc(100% - 10px) !important;
}

.slick-list div {

    @media screen and (max-width: 400px) {

        height: 500px;
    }
}

.bgmenu {
    background-image: url("./assets/img/PARTICULAS.png");
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: 1368px) {
        background-image: url("./assets/img/PARTICULAS.png");
        background-size: cover;
    }

}

.promesacontianer {
    background-image: url("./assets/img/Promesa.png");
    background-size: calc(100% - 100px);
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;

    @media screen and (max-width: 1368px) {
        background-size: calc(100% + 0px);
    }
}


.FondoAboutUs {
    background-image: url("https://awna.s3.sa-east-1.amazonaws.com/Fondos/FondoAboutUs.png");
    background-size: calc(100%);
    ;
}

.smooth-slider .swiper-wrapper {
    transition-timing-function: linear;
}

.opacity-desktop {
    opacity: 0.2;
}

.opacity-mobile {
    opacity: 1;
}

.animation-molecula {
    animation: spin 10s linear infinite;
}

.animation-molecula-reverse {
    animation: spin 10s linear infinite;
    animation-direction: reverse;
}

.ant-collapse-header {
    cursor: none !important;
}